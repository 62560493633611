class Room {
    constructor(containerId) {
        this.levelNames = [
           // 'doubleWall',
           'hiddenTreasure',
           'boooooo',
           'dancingCubes',
           'museum',
           'courtyard',
           'galaxyDisplay',
           'crystalCave',
           'lightTunnel',
           // 'infiniteMirror',
           'dreamScape',
           'clouds',
           // 'skyLift',
           'castle',
           '404',
           'underwaterGarden'
        ]
        this.container = document.getElementById(containerId);
        this.levelName = null
        this.firstInteractionHappened = false
        this.spawnProtection = false
        this.player = `
            <a-entity id="player" position="0 0 0">
                <a-entity id="rig"
                    movement-controls
                    kinematic-body
                    jump-ability
                    >
                    <a-entity
                        id="camera"
                    look-controls="pointerLockEnabled: true"
                        camera
                        camera="fov: 110"
                        position="0 1.6 0"
                        >
                    </a-entity>
                </a-entity>
            </a-entity>
        `
        this.init()
    }

    init() {
        this.helpMenu = new HelpMenu()
        window.clearLevel = () => this.clearLevel()
        window.intervals = []
        window.loadRandomLevel = () => this.loadRandomLevel()
        window.addCollisionDetection = () => this.addCollisionDetection()

        const levelName = window.location.pathname.replace('/', '')
            || new URLSearchParams(window.location.search).get('room')
        if (levelName) {
            this.loadLevel(levelName)
        } else {
            this.loadRandomLevel()
        }
    }

    handleFirstInteraction() {
        if (!this.firstInteractionHappened) {
            this.firstInteractionHappened = true
            this.soundManager.play()
            window.removeEventListener('keydown', this.firstInteractionHandler)
            this.container.removeEventListener('click', this.firstInteractionHandler)
        }
    }

    loadSoundManager(levelName) {
        if (this.soundManager) {
            this.soundManager.stop()
        }
        this.soundManager = null
        this.soundManager = new SoundManager(`sounds/${levelName}.mp3`)
        this.soundManager.setVolume(0.3)
        this.firstInteractionHandler = this.handleFirstInteraction.bind(this)
        window.addEventListener('keydown', this.firstInteractionHandler)
        this.container.addEventListener('click', this.firstInteractionHandler)
    }

    addCollisionDetection() {
        const thing = document.querySelector('#thing')
        if (thing){
            thing.addEventListener('collide', (e) => {
                if (e.detail.body.el.id === 'rig' && !this.spawnProtection) {
                    console.log('Collided with the thing')
                    if (this.soundManager) {
                        this.soundManager.playSoundOnce('sounds/laugh.mp3')
                    }
                    this.loadRandomLevel()
                }
            })
        } else {
            const things = document.querySelectorAll('.thing')
            things.forEach((thing) => {
                thing.addEventListener('collide', (e) => {
                    if (e.detail.body.el.id === 'rig') {
                        console.log('Collided with the thing')
                        if (this.soundManager) {
                            this.soundManager.playSoundOnce('sounds/laugh.mp3')
                        }
                        this.loadRandomLevel()
                    }
                })
            })
        }
    }

    loadRandomLevel() {
        let randomLevelName = this.levelNames[Math.floor(Math.random() * this.levelNames.length)]
        while (randomLevelName === this.levelName) {
            randomLevelName = this.levelNames[Math.floor(Math.random() * this.levelNames.length)]
        }
        while (randomLevelName === '404') {
            randomLevelName = this.levelNames[Math.floor(Math.random() * this.levelNames.length)]
        }
        this.loadLevel(randomLevelName)
    }

    loadLevel(levelName) {
        if (this.levelNames.includes(levelName)) {
            this.clearLevel()
            document.title = levelName
            const pathLevelName = window.location.pathname ? window.location.pathname.substring(1) : null
            const queryLevelName = new URLSearchParams(window.location.search).get('room')
            if (!queryLevelName && pathLevelName !== levelName) {
                window.history.pushState({}, '', `/${levelName}`)
            } else if (!pathLevelName && queryLevelName !== levelName) {
                window.history.pushState({}, '', `?room=${levelName}`)
            }
            this.loadLevelHtml(levelName).then((html) => {
                this.container.innerHTML = html
                this.levelName = levelName
                this.loadPlayer()
                this.addCollisionDetection()
                this.loadLevelJs(levelName)

                this.loadSoundManager(levelName)
                this.soundManager.play()

                this.lockCamera()
            })
        } else {
            console.error(`Level "${levelName}" does not exist.`)
            this.clearLevel()
            this.levelName = '404'
            document.title = levelName
            this.loadLevel('404')
        }
    }

    lockCamera() {
        const sceneEl = document.querySelector('a-scene')
        const cameraEl = sceneEl.querySelector('#camera')
        if (cameraEl) {
            cameraEl.requestPointerLock()
        }
    }


    loadPlayer() {
        const scene = document.querySelector('a-scene')
        scene.insertAdjacentHTML('beforeend', this.player)
    }

    loadLevelHtml(levelName) {
        return new Promise((resolve, reject) => {
            return fetch(`src/rooms/${levelName}.html`)
                .then(function (response) {
                    resolve(response.text())
                })
                .catch(function (error) {
                    console.log('Error:', error)
                    reject(error)
                })
        })
    }

    loadLevelJs(levelName) {
        const script = document.createElement('script')
        script.src = `src/rooms/${levelName}.js`
        document.getElementsByTagName('head')[0].appendChild(script)
    }

    clearLevel() {
        while (this.container.firstChild) {
            this.container.removeChild(this.container.firstChild)
        }
        window.intervals?.forEach((interval) => {
            clearInterval(interval)
        })
        if (this.soundManager && !this.soundManager.audio.paused) {
            this.soundManager.stop()
        }
    }
}

class SoundManager {
    constructor(audioFileUrl) {
        this.audio = new Audio(audioFileUrl)
        this.audio.loop = true
    }
    play() {
        this.audio.currentTime = 0
        this.audio.play()
    }
    stop() {
        this.audio.pause()
        this.audio.currentTime = 0
    }
    setVolume(volumeLevel) {
        this.audio.volume = volumeLevel;
    }
    playSoundOnce(soundFileUrl) {
        const audio = new Audio(soundFileUrl)
        audio.play()
    }
}

class HelpMenu {
    constructor() {
        this.helpMenu = document.createElement('div');
        this.helpMenu.innerHTML = 
        // if on any ios or android device, not desktop
            this.helpMenu.innerHTML = (navigator.userAgent.match(/(iPod|iPhone|iPad)/) || navigator.userAgent.match(/(Android)/)) ? `
            <div id="help-menu" style="display: none; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 1000; background: white; padding: 20px; border-radius: 10px;">
                <h1>room space cloud</h2>
                <h2>how to move:</h2>
                <p>tap one finger to move forward</p>
                <p>tap two fingers to move backwards</p>
                <p>move your phone or swipe left and right to look around</p>
                <p>Goal: Find the thing</p>
            </div>
            ` : `
            <div id="help-menu" style="display: none; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 1000; background: white; padding: 20px; border-radius: 10px;">
                <h1>room space cloud</h2>
                <h2>how to move:</h2>
                <p>WASD keys to move or arrow keys</p>
                <p>Click and move mouse to look around</p>
                <p>Goal: Find the thing</p>
            </div>
        `;
        document.body.appendChild(this.helpMenu);
        this.helpMenuActive = false;
        this.lastInteraction = Date.now();
        this.hasInteracted = false;
        this.lastInteractionCheckInterval = null;
        this.setEventListeners();
    }

    setEventListeners() {
        window.addEventListener('keydown', (e) => {
            if (!this.hasInteracted) {
                this.hasInteracted = true;
                clearInterval(this.lastInteractionCheckInterval);
            }
            if (e.key === 'h') {
                this.toggleHelpMenu();
            }
            if (e.key === 'Escape' || e.key === 'x' || e.key === 'w'
            || e.key === 'a' || e.key === 's' || e.key === 'd' || e.key === 'ArrowUp'
            || e.key === 'ArrowLeft' || e.key === 'ArrowDown' || e.key === 'ArrowRight') {
                this.helpMenuActive = false;
                this.helpMenu.querySelector('#help-menu').style.display = 'none';
                clearInterval(this.lastInteractionCheckInterval);
            }
            this.lastInteraction = Date.now();
        });

        window.addEventListener('mousemove', () => {
            this.lastInteraction = Date.now();
            this.helpMenuActive = false;
            this.helpMenu.querySelector('#help-menu').style.display = 'none';
        });

        window.addEventListener('touchstart', () => {
            this.lastInteraction = Date.now();
            this.helpMenuActive = false;
            this.helpMenu.querySelector('#help-menu').style.display = 'none';
            clearInterval(this.lastInteractionCheckInterval);
        })

        this.lastInteractionCheckInterval = setInterval(() => {
            if (Date.now() - this.lastInteraction > 5000 && !this.helpMenuActive) {
                this.toggleHelpMenu();
            }
        }, 1000);
    }

    toggleHelpMenu() {
        this.helpMenuActive = !this.helpMenuActive;
        this.helpMenu.querySelector('#help-menu').style.display = this.helpMenuActive ? 'block' : 'none';
        if (!this.helpMenuActive) {
            clearInterval(this.lastInteractionCheckInterval);
        }
    }
}


new Room('room-container')